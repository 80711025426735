* {
  margin:0px;
  padding:0px;
  max-height:100vh;
  width:100%;
  overflow: hidden;
}

li {
  font-family: 'Raleway', sans-serif;
  font-size: .95rem;
  letter-spacing: 0.2em;
  line-height: 1.3rem;
  text-align: left;
  padding:.25rem;
}

p {
  font-family: 'Raleway', sans-serif;
  font-size: .95rem;
  letter-spacing: 0.2em;
  line-height: 1.3rem;
  text-align: left;
}

h2 {
  font-size: 1.3em;
}

p.connect {
  text-align: left;
  transform: translateX(25px);
}

.App {
  background: rgb(245,202,195);
  background: linear-gradient(162deg, rgba(245,202,195,1) 0%, rgba(247,237,226,1) 100%);
  display: flex;
  flex-direction:column;
}

.page-section {
  height:100vh;
}

.side-bar {
  background-color: rgba(255,255,255, .9);
  display:inline-flex;
  flex-direction: column;
  width:100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display:flex;
  justify-content: space-between;
  /* align-items: center; */
  height:7vh;
  /* padding-top:3%; */
}

#internal-links {
  margin-top:auto;
  margin-bottom:auto;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}

.nav-link{
  width:fit-content;
  text-decoration: none;
}

.nav-icon[selected] {
  color:rgba(242, 132, 130, 1);
  border-color:rgba(242, 132, 130, 1);
}

.nav-icon {
  width:fit-content;
  height:fit-content;
  font-family: 'Bebas Neue';
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  color:black;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-color:rgba(255,255,255, 0);
  padding-bottom:3px;
  padding-top:5px;
}

.external-links {
  display:inline-flex;
  flex-direction: column;
}

.link-icon {
  width:1.75rem;
  margin:0.5rem 0rem 1.5rem 0rem;
}

#code-icon {
  width:2rem;
}

#home-page {
  width:fit-content;
  margin-right:auto;
  margin-left:auto;
}

#home {
  height:100vh;
  width:fit-content;
  display:flex;
  flex-direction: column;
}

.title {
  display:inline-flex;
  flex-direction: column;
  text-align: left;
  width:fit-content;
  margin-top:10%;
}

.name {
  font-family: 'Bebas Neue';
  font-size: 6.2rem;
  letter-spacing: 0.01em;
  color:black;
  text-shadow: 0 1px 0 rgba(242,132,130, 0.4);
  margin-left:0.1rem;
}

.sub-title {
  font-family: 'Bebas Neue', cursive;
  color:white;
  letter-spacing: 0.615em;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  margin:0.5rem 0rem 0.5rem 0rem;
}

#fullstack-dev {
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.contact-menu {
  background: rgba(242, 132, 130, 1);
  box-shadow: 0 0 0 0 rgba(242, 132, 130, 1);
  animation: pulse-pink 3s infinite;
  color:white;
  font-family: 'Raleway', sans-serif;
  font-size: 1.15rem;
  height:40px;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width:400px;
  border-radius: 10px;
  margin:0.75rem 0rem 0.5rem 0.5rem;
  padding:0.25rem;

}

@keyframes pulse-pink {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.contact-menu:hover {
  animation: pulse-pink none;
}

.contact-icons {
  display:flex;
  justify-content:space-between;
  width:600px;
  margin-right:1.0rem;
}

 .contact-icon {
  height:1.75rem;
  width:fit-content;
}

.contact-icon:hover {
  transform: translate(2px, 2px);
}

#email {
  transform: translateY(1px);
}

#email:hover {
  transform: translate(2px, 2px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* RESUME STYLING */

.resume-container {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height:85vh;
  align-content: space-around;
}

.pdf-link {
  height: fit-content;
  width:fit-content;
  margin-right:10px;
}

.pdf-icon {
  height: 1.9rem;
  width:fit-content;
  margin:0px;
}

.experience-card {
  background-color: rgba(255,255,255, .9);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 39rem;
  height:10rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.experience-link {
  text-decoration: none;
  color:black;
  margin:1rem;
  width: 40rem;
  height:11rem;
  border-radius: 10px;
}

.experience-card:hover {
  transform:translate(.25rem, .25rem);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  transition-duration: 0.2s;
}

.title-items {
  display:flex;
  flex-direction: row;
  align-items: center;
  color:black;
  width:fit-content;
}

.job-icon {
  height:2rem;
  width:fit-content;
  margin-right:0.5rem;
}

.role-title {
  color:black;
  filter: none;
  width:fit-content;
  letter-spacing: 0.4em;
  margin:0rem .25rem 0rem .25rem;
  font-family: 'Bebas Neue', cursive;
}

.role-details {
  font-family: 'Raleway';
  letter-spacing: 0.2em;
  width:fit-content;
  height:fit-content;

}

#top-corner {
  height:1rem;
  width:1rem;
  border-color: grey;
  border-style: solid;
  border-top: 1px;
  border-left:1px;
  align-self:flex-start;
  margin-right:1rem;
  position: absolute;
  transform: translate(1rem, -3.5rem) rotate(180deg);
}

#bottom-corner {
  height:1rem;
  width:1rem;
  border-color: grey;
  border-style: solid;
  border-top: 1px;
  border-left:1px;
  align-self:flex-end;
  margin-right:1rem;
  position: absolute;
  transform: translate(0rem, 3.5rem);
}

.resume-details-header {
  display:flex;
  width:85%;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
}

.back-icon-container {
  z-index: 9;
  width:fit-content;
  text-align: left;
  margin-top:0.5rem;
}

.back-icon {
  z-index: 9;
  height: 2.5rem;
  width: fit-content;
  
}

#detail-title {
  margin-left:auto;
  margin-right:auto;
}

#resume-sub-section {
  height: 100vh;
}

#job-details-container {
  height:100vh;
  width:85%;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding:1.5rem;
}

.job-detail {
  background-color: rgba(255,255,255, .9);
  height:fit-content;
  padding:2rem; 
  width:30%;
}

#summary-div {
  width:100%;
}

#skills-demonstrated-div {
  flex-grow: 2;
  margin-left: 2rem;
  align-self: stretch;
}

#skills-container {
  align-self: stretch;
}

li.skill-item {
  list-style: square;
}

.sub-title-black {
  font-family: 'Bebas Neue', cursive;
  color:black;
  letter-spacing: 0.615em;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  width:fit-content;
}

.sub-title-job {
  font-family: 'Bebas Neue', cursive;
  color:black;
  letter-spacing: 0.615em;
  margin:0.5rem;
}

.test {
  height: 100%;
}

/* PROJECTS */

.projects-container {
  height:100vh;
  overflow: scroll;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
}

.page-title {
  display:flex;
  margin-right:auto;
  margin-left:auto;
  width:fit-content;
  align-items: flex-end;
  margin: 2rem auto 0px auto;
  padding-bottom:.5rem;
}

.title-black {
  font-family: 'Bebas Neue', cursive;
  color:black;
  letter-spacing: 0.615em;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  width:fit-content;
}

.project-card {
  background-color: rgba(255,255,255, .8);
  height:19rem;
  width:28rem;
  margin:2rem auto 2rem auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow-y: clip;
  padding-top:1rem;
  position:relative;
  transition-duration: .5s;
}

.project-card[expanded] {
  height:38rem;
  transition-duration: 1s;
}

.project-card[expanded] .arrow-container {
  transform:rotate(180deg);
  transition-duration: .5s;
}

.card-title {
display:flex;
margin-left: auto;
margin-right:auto;
width:fit-content;
height:3.5rem;
}

.close-icon {
  width:2rem;
  margin:0rem;
}

.project-image {
  width:70%;
  height:auto;
  margin:1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.arrow-container {
  height: 2rem;
  display: flex;
  justify-content: center;  
  transition-duration: .5s;
}

.expand-arrow {
  transform:rotate(45deg);
  height:0.75rem;
  width:0.75rem;
  border-color: grey;
  border-style: solid;
  border-top: 1px;
  border-left:1px;
}

.button-container {
  display: flex;
  width:85%;
  justify-content: space-between;
  padding:2rem 1rem 1.25rem 1rem;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:0.5rem;
}

button {
  width:fit-content;
  height:2rem;
  background:#F28482;
  color:white;
  display:flex;
  align-items: center;
  border-style: none;
  border-radius: 10px;
  padding:1.0rem;
  font-size: 1.0rem;
  font-family: 'Raleway', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: 0 0 0 0 rgba(242, 132, 130, 1);
  animation: pulse-pink 3s infinite;
}

button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transform:translate(.25rem, .25rem);
  transition-duration: 0.2s;
}

#video-icon {
  height:1.5rem;
  width:fit-content;
  margin: 0rem .25rem 0rem .25rem;
}

.tools-container {
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  width:85%;
  margin:auto;
}

.tool-icon {
  width:fit-content;
  background:#84A59D;
  color:white;
  align-items: center;
  border-radius: 10px;
  padding:0.5rem;
  margin:0.5rem;
}
